<template>
  <div>
    <feather-icon
      title="View Details"
      icon="EyeIcon"
      svgClasses="h-5 w-5 mr-2 hover:text-primary cursor-pointer"
      @click="displayRecord"
    />
    <feather-icon
      v-if="userHasPermission('apps_customers_edit,apps_customers_add')"
      title="Edit"
      icon="Edit3Icon"
      svgClasses="h-5 w-5 mr-2 hover:text-primary cursor-pointer"
      @click="editRecord"
    />

    <feather-icon
      title="View Usage"
      icon="BarChartIcon"
      svgClasses="h-5 w-5 hover:text-primary cursor-pointer"
      @click="displayCreditUsage"
    />
    <vs-prompt
      title="Delete Customer"
      accept-text="Delete"
      @cancel="closeDeleteDialog"
      @close="closeDeleteDialog"
      @accept="deleteCustomer"
      :active.sync="showDeleteDialog"
    >
      <div class="vx-row mt-2 mb-4">
        <div class="vx-col">         
          <span 
            >Are you sure you want to delete customer?
              </span>
          
        </div>       
        </div>      
    </vs-prompt>
  </div>
</template>

<script>
import TpNotificationMixin from "@/components/travio-pro/TpNotificationMixin.vue";
import vSelect from "vue-select";
import _ from "lodash";
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';
export default {
  name: "CellRendererActions",
  components: {
    vSelect,
  },
  mixins: [TpNotificationMixin],
  data() {
    return {
      showDeleteDialog: false,
    };
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    editRecord() {     
     this.$router.push({
        name: "company-credit-limit-editor-edit",
        params: {
          applicationId: this.$route.params.applicationId,
          companyId: this.$route.params.companyId,
          creditId: this.params.data.id
        },
      })
      .catch((error) => console.error(error));
    },
    displayRecord() {
      this.$router.push({
        name: "company-credit-limit-editor-view",
        params: {
          applicationId: this.$route.params.applicationId,
          companyId: this.$route.params.companyId,
          creditId: this.params.data.id
        },
      })
      .catch((error) => console.error(error));
    },
    displayCreditUsage () {
      this.$router.push({
        name: "company-credit-usage-view",
        params: {
          applicationId: this.$route.params.applicationId,
          companyId: this.$route.params.companyId,
          creditId: this.params.data.id
        },
      })
      .catch((error) => console.error(error));
    },
    confirmDeleteRecord() {
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    deleteCustomer() {
      // if (this.deleteAffectedUsersCount > 0 && !this.selectedReplacementPriceRule ) {
      //   this.$_notifyFailure('Unable to delete priceRule. Please select replacement priceRule for affected users.')
      //   return
      // }
      const payload = {
         applicationId: this.$route.params.applicationId,
         customerId: this.params.data.customerId,
      };
      this.$vs.loading();
      this.$store
        .dispatch("customerStore/removeCustomer", payload)
        .then((response) => {
           return this.$store.dispatch(
            "customerStore/fetchCustomers",
            this.$route.params.applicationId
          ).then((res)=>{
            this.$_notifySuccess("Successfully deleted customer");
          });
        })
        .catch((error) => {
          this.$_notifyFailureByResponseData(error.response.data);
        })
        .finally(() => this.$vs.loading.close());
    },
  },
};
</script>
