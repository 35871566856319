<template>
  <travio-center-container grid-width='2/3' :pageTitle="pageTitle">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div class="vx-row">
          <vs-button v-if="userHasPermission('apps_customers_edit')" @click="addCreditLimit" class="ml-4 sm:mb-0 mb-2">
            Add
          </vs-button>
           <vs-button type="border" v-if="userHasPermission('apps_customers_edit')" @click="manageCompanies" class="ml-4 sm:mb-0 mb-2">
            Back To Companies List
          </vs-button>
        </div>

        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="creditLimits"
          :gridOptions="gridOptions"
          rowSelection="single"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @grid-size-changed="onGridSizeChanged"
        >
        </ag-grid-vue>
      </vx-card>

    </div>
  </travio-center-container>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import CellRendererCreditLimitActions from "./cell-renderers/CellRendererCreditLimitActions.vue";
import CellRendererActiveCheckbox from "./cell-renderers/CellRendererActiveCheckbox.vue";
import Vue from "vue";
import { format } from 'date-fns'
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';
import { th } from 'date-fns/locale';

export default {
  components: {
    AgGridVue,
    CellRendererCreditLimitActions,
    CellRendererActiveCheckbox
  },
  props: {
    applicationId: { required: true},
    companyId : { required: true}
  },
  data() {
    return {      
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererCreditLimitActions,
        CellRendererActiveCheckbox,
      },
      companyName: "",
      showDeletePriceRulePopup: false,
      selectedReplacementPriceRule: {},
      creditLimits: [],
    };
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    pageTitle () {
      return `Manage Credit Limit For Company ${this.companyName}`;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    preferedDateFormat() {      
      return this.$store.state.accountStore.generalSettings.dateFormat;
    },
    titleOptions () {
      return this.$store.state.lookups.titleOptions
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
    
  },
  created() {
    
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.$route.params.applicationId)) ) {
      this.$router.push('/error-404')
    }

    this.$vs.loading()
    this.$http.get(`api/b2b/companies/${this.$route.params.applicationId}/${this.$route.params.companyId}/credit`)
    .then(response => {
      this.companyName = response.data.companyName
      this.creditLimits = response.data.creditLimits
    })
    .catch(error => console.error(error))
    .finally(() => this.$vs.loading.close())  

  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs = [
      {
        headerName: "Actions",
        width: 110,
        sortable: false,
        suppressSizeToFit: true,
        cellRendererFramework: Vue.extend(CellRendererCreditLimitActions),
      },
      { 
        headerName: "ID",
        width: 80, 
        field: "id",
        suppressSizeToFit: true, 
      },
      { 
        headerName: "Credit Reference",
        width: 180, 
        field: "creditReference",
        suppressSizeToFit: true, 
      },
      {
        headerName: "Amount",
        width:160,
        sortable: true,        
        suppressSizeToFit: true,
        valueGetter: params => params.data.amount && this.currencyFormatter(params.data.amount, params.data.currencyCode),
        // cellStyle: { textAlign: 'right' }
      }, 
      { 
        headerName: "Currency",
        width: 120, 
        field: "currencyCode",
        suppressSizeToFit: true, 
      },
      { headerName: 'IsActive', field: 'isActive', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererActiveCheckbox) }, 
      { 
        headerName: 'Created Date',
        field: 'createdDateTime',
        sortable: true,
        width: 160, 
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateTimeFormat)
      }
    ];

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true,
    };
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },

  methods: {
    currencyFormatter(amount, currencyCode) {
      var amtDecimal = amount.toFixed(2);
      return `${currencyCode} ${amtDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    },
    activeFormatter(params){
      return params.data.isActive == 1 ? 'Active' : 'Inactive';
    },  
    addCreditLimit() {
      this.$router.push({
        name: "company-credit-limit-editor-add",
        params: { applicationId: this.applicationId },
        params: { companyId: this.companyId },
      });
    },
    manageCompanies () {
      this.$router.push({
        name: "company-list",
        params: { applicationId: this.applicationId },
      });
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
    },
    onGridSizeChanged(params) {
      if (params.clientWidth > 500) {
        this.gridApi.sizeColumnsToFit();
      } else {
        this.columnDefs.forEach(x => {
          x.width = 300;
        });
        this.gridApi.setColumnDefs(this.columnDefs);
      }
    }
  },
};
</script>

<style>
</style>
